"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
const mobx_1 = require("mobx");
class PublicPrivacyNoticeStore {
    constructor(rootStore) {
        Object.defineProperty(this, "isPublicPrivacyNoticeOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "showContactModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "contactModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translateStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this);
        this.translateStore = rootStore.translateStore;
    }
    openContactInformationModal(type, heading, textAreaComment) {
        this.showContactModal = true;
        this.contactModal = {
            type,
            heading: heading ? heading : this.translateStore.translate('gdpr.privacy.notification.paragraph.nine.modal2.modal.defaultHeading'),
            textAreaComment
        };
    }
    closeContactInformationModal() {
        this.showContactModal = false;
        this.contactModal = null;
    }
}
exports.default = PublicPrivacyNoticeStore;
__decorate([
    mobx_1.observable
], PublicPrivacyNoticeStore.prototype, "isPublicPrivacyNoticeOpen", void 0);
__decorate([
    mobx_1.observable
], PublicPrivacyNoticeStore.prototype, "showContactModal", void 0);
__decorate([
    mobx_1.observable
], PublicPrivacyNoticeStore.prototype, "contactModal", void 0);
__decorate([
    mobx_1.action
], PublicPrivacyNoticeStore.prototype, "openContactInformationModal", null);
__decorate([
    mobx_1.action
], PublicPrivacyNoticeStore.prototype, "closeContactInformationModal", null);
