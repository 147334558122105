"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_LANGUAGE = void 0;
const crm_components_1 = require("crm-components");
const mobx_1 = require("mobx");
exports.DEFAULT_LANGUAGE = crm_components_1.Language.ET;
const ALLOWED_LANGUAGES = [crm_components_1.Language.ET, crm_components_1.Language.RU, crm_components_1.Language.EN];
class LocationStore {
    constructor(translateStore) {
        Object.defineProperty(this, "currentLocation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "redirectUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "translateStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        (0, mobx_1.makeObservable)(this);
        this.translateStore = translateStore;
    }
    setLanguage(language) {
        if (this.currentLanguage !== language) {
            (0, crm_components_1.setLanguage)(language);
            this.translateStore.fetchTranslations(language);
        }
    }
    setCurrentLocation(currentLocation) {
        this.currentLocation = currentLocation;
    }
    getHeaderLanguages() {
        return ALLOWED_LANGUAGES.map((language) => {
            return {
                id: language,
                label: this.getLanguageLabel(language),
                onClick: (event) => {
                    event.preventDefault();
                    if (language !== this.currentLanguage) {
                        this.setLanguage(language);
                    }
                },
                url: '#',
                current: language === this.currentLanguage
            };
        });
    }
    getLanguageLabel(language) {
        switch (language) {
            case crm_components_1.Language.EN:
                return 'In English';
            case crm_components_1.Language.ET:
                return 'Eesti keeles';
            case crm_components_1.Language.RU:
                return 'По-русски';
            default:
                throw new Error('Unknown language!');
        }
    }
    get currentLanguage() {
        return (0, crm_components_1.getCurrentLanguage)();
    }
}
__decorate([
    mobx_1.observable
], LocationStore.prototype, "currentLocation", void 0);
__decorate([
    mobx_1.action
], LocationStore.prototype, "setCurrentLocation", null);
exports.default = LocationStore;
