"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasURLParam = exports.parseParams = void 0;
function parseParams(textValue, paramObject) {
    return Object.keys(paramObject).reduce((text, key) => text.replace(`\${${key}}`, paramObject[key]), textValue);
}
exports.parseParams = parseParams;
function hasURLParam(name, expectedValue) {
    const params = new URLSearchParams(unescape(window.location.search));
    const param = params.get(name);
    if (param === null) {
        return false;
    }
    return param.toLowerCase() === expectedValue;
}
exports.hasURLParam = hasURLParam;
